import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {uniq} from 'lodash';

import {markupDescription} from 'web/helpers/markup';
import MarketLayout from 'web/components/market_layout';
import MarketSidebar from 'web/components/market_sidebar';
import MarketTile from 'web/components/market_tile';
import {reducer as signInFlowReducer} from 'web/components/sign_in_flow/duck';
import PreorderAnnouncement from 'web/market/product_listings_page/components/preorder_announcement';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';
import {
  reducer as signUpModalReducer,
  actions as signUpModalActions,
} from 'web/helpers/sign_up_modal_duck';
import ThanksgivingBanner from 'web/market/product_listings_page/components/thanksgiving_banner';

import {actions, reducer as listingsReducer} from './duck';
import AlcoholComingSoonForm from './components/alcohol_coming_soon_form';

const segmentFeature = 'market';

const SEO_CATEGORIES = ['thanksgiving', 'holiday'];

if (typeof window !== 'undefined' && window !== null) {
  window.lazySizesConfig = {
    init: false,
    preloadAfterLoad: false,
  };

  // lazysizes doesn't load server side
  // eslint-disable-next-line global-require
  require('lazysizes');
}

function TagHeader(props) {
  return (
    <div
      className={classNames('static-tag-header', {
        'improved-tag': Boolean(props.description),
      })}
    >
      <div className="text-area">
        <h2>{props.tagName}</h2>
        {props.description ? (
          <span
            dangerouslySetInnerHTML={markupDescription(props.description)}
            className="description"
          />
        ) : null}
      </div>
    </div>
  );
}

TagHeader.propTypes = {
  description: PropTypes.string,
  tagName: PropTypes.string,
  tagSlug: PropTypes.string,
};

function VendorHeader(props) {
  return (
    <div className="webstand" itemScope itemType={'http://schema.org/Organization'}>
      <div className="content">
        <div className="vendor-image-container">
          <img className="vendor-image" alt={props.vendor.name} src={props.imageUrl} />
        </div>

        <div className="details">
          <h1 className="vendor-name">{props.vendor.name}</h1>
          <div className="vendor-locations-container">
            {props.vendor.city ? (
              <span>
                <i className="icon icon-pin" />
                <div
                  className="vendor-address"
                  itemProp="address"
                  itemScope
                  itemType={'http://schema.org/PostalAddress'}
                >
                  <span itemProp="addressLocality">{props.vendor.city}</span>
                </div>
              </span>
            ) : null}

            {props.vendor.websiteUrl ? (
              <a
                className="vendor-website"
                itemProp="url"
                target={props.vendor.slug}
                href={props.vendor.websiteUrl}
              >
                Our website
              </a>
            ) : null}
          </div>
          <div className="description-container">
            <div
              dangerouslySetInnerHTML={markupDescription(props.vendor.description)}
              className="description"
              itemProp="description"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

VendorHeader.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    slug: PropTypes.string,
    websiteUrl: PropTypes.string,
    city: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
};

function Section(props) {
  const sectionId = `${props.subcategory.id}-${props.section.slug}`;
  return (
    <div id={props.section.slug} className="section-page" key={sectionId}>
      <div className="product-listings-view__section-anchor" id={sectionId} />
      {props.renderHeader ? (
        <h3 className="listings-section-header">{props.section.name}</h3>
      ) : null}
      <div className="page">
        <div className="grid-items">
          {props.section.productInfos.map((product, i) => (
            <MarketTile
              product={product}
              key={`${sectionId}-${product.id}`}
              segmentFeature={segmentFeature}
              position={props.productPositionIndex + i + 1}
              context={{subcategoryName: props.subcategory.id}}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

Section.propTypes = {
  renderHeader: PropTypes.bool,
  section: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    productInfos: PropTypes.array,
  }),
  subcategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  productPositionIndex: PropTypes.number,
};

function Subcategories(props) {
  let prevProductCount = 0;

  if (props.isAlcoholComingSoon) {
    return <AlcoholComingSoonForm user={props.user} />;
  }
  return (
    <div
      className={classNames('products-page', 'product-listings-view', {
        'with-sidebar': !props.isWebstand,
      })}
    >
      <div>
        <ThanksgivingBanner />
      </div>
      {props.populatedSubcategories.map((subcategory) => {
        const subcategoryId = subcategory.subcategory.id;
        return (
          <div className="product-tiles" key={subcategoryId} data-testid="product-tiles">
            <div className="product-listings-view__subcategory-anchor" id={subcategoryId} />
            <h2 className="listings-section-header">{subcategory.subcategory.name}</h2>
            {subcategory.sections.map((section) => {
              const productPositionIndex = prevProductCount;
              prevProductCount += section.productInfos.length;
              return (
                <Section
                  subcategory={subcategory.subcategory}
                  section={section}
                  productPositionIndex={productPositionIndex}
                  renderHeader={subcategory.sections.length > 1}
                  key={`${subcategoryId}-${section.slug}`}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

Subcategories.propTypes = {
  populatedSubcategories: PropTypes.array,
  isWebstand: PropTypes.bool,
  isAlcoholComingSoon: PropTypes.bool.isRequired,
};

function BottomNav(props) {
  return (
    <div className="products-page__bottom-navigation-links">
      <div className="products-page__bottom-navigation-links-container">
        <div className="products-page__bottom-navigation-links-link">
          {props.previousSubcategory ? (
            <div>
              <i className="icon icon-chevron" />
              <a
                className="products-page__bottom-navigation-links-anchor"
                href={`/${props.categoryId}/${props.previousSubcategory.id}`}
              >
                {props.previousSubcategory.name}
              </a>
            </div>
          ) : null}
        </div>
        <div className="products-page__bottom-navigation-links-link">
          {props.nextSubcategory ? (
            <div>
              <a
                className="products-page__bottom-navigation-links-anchor"
                href={`/${props.categoryId}/${props.nextSubcategory.id}`}
              >
                {props.nextSubcategory.name}
              </a>
              <i className="icon icon-chevron" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

BottomNav.propTypes = {
  nextSubcategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  categoryId: PropTypes.string,
  previousSubcategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

class ProductListingsPage extends Component {
  static reducer(state, action) {
    state = MarketLayout.reducer(state, action);
    state = listingsReducer(state, action);
    state = signInFlowReducer(state, action);
    state = signUpModalReducer(state, action);
    return state;
  }

  constructor(props) {
    super(props);

    this.sideBarGroups = this.buildSidebarGroups();

    this.nextRenderQueued = false;
    this.nextRenderProductIds = [];

    this.state = {
      isModalOpen: false,
      signUpAddProductId: null,
    };
  }

  componentDidMount() {
    if (this.props.category) {
      window.metrics.setPageProperty('category', this.props.category.id);
    }

    document.addEventListener('lazybeforeunveil', (e) => {
      e.preventDefault();
      const productId = e.target.getAttribute('data-product-id');
      this.nextRenderProductIds.push(productId);
      // batch a set of productIds provided to us from lazysizes events
      if (!this.nextRenderQueued) {
        window.requestAnimationFrame(() => {
          this.props.renderProducts(this.nextRenderProductIds);
          this.nextRenderProductIds = [];
          this.nextRenderQueued = false;
        });
        this.nextRenderQueued = true;
      }
    });
  }

  buildSidebarGroups() {
    return this.props.populatedSubcategories.map((subcat) => ({
      displayName: subcat.subcategory.name,
      id: subcat.subcategory.id,
      menuItems: subcat.sections.map((section) => ({id: section.slug, displayName: section.name})),
    }));
  }

  renderSidebar(isAlcoholComingSoon) {
    if (isAlcoholComingSoon) {
      return;
    }

    return (
      <div className="product-listings-page__sidebar">
        <MarketSidebar menuGroups={this.sideBarGroups} />
      </div>
    );
  }

  render() {
    const hidePreorderDays =
      this.props.features?.includes(FeatureFlags.HidePreorderDaySelector) ?? false;
    const preorderPeriod = this.props.upcomingPreorderPeriods?.[0];

    let title = `Market | Good Eggs`;
    // If browsing a market category, show the display name of the category in the title.
    if (this.props.category != null) {
      title =
        this.props.category?.id === 'holiday'
          ? 'Good Eggs | Fresh Ingredients and Prepared Holiday Meals'
          : `${this.props.category.shortName} Fresh Home Delivery To Your Door | Good Eggs`;
    }
    // If browsing by tag, show the display name of the tag in the title.
    if (this.props.tagName) {
      title = `${this.props.tagName} | Good Eggs`;
    }

    if (this.props.vendor) {
      title = `${this.props.vendor.name} | A Good Eggs Partner`;
    }

    // (@shermam) When we are hiding the preorder days we want the banner to stick around even it we select a preorder day
    const showPreorderAnnouncement =
      preorderPeriod != null &&
      (hidePreorderDays || !preorderPeriod.preorderDays.includes(this.props.currentFulfillmentDay));

    const isAlcoholComingSoon =
      (this.props.foodhub.slug === 'VRN1' && this.props.category?.id === 'alcohol') ?? false;

    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
      >
        <Helmet>
          <title>{title}</title>
          <meta
            name="google-signin-client_id"
            content={this.props.settings.googleAuthenticateClientId}
          />
          {this.props.category?.id && SEO_CATEGORIES.includes(this.props.category.id) && (
            <meta
              name="description"
              property="og:description"
              content={this.props.category.description}
            />
          )}
        </Helmet>

        {showPreorderAnnouncement ? (
          <PreorderAnnouncement
            preorderPeriod={preorderPeriod}
            shouldRedirect={
              !(this.props.category?.id && SEO_CATEGORIES.includes(this.props.category.id))
            }
            onClickCTA={this.props.onClickPreorderCTA}
          />
        ) : null}

        {this.props.tagName ? (
          <TagHeader
            description={this.props.tagDescription}
            tagName={this.props.tagName}
            tagSlug={this.props.tagSlug}
          />
        ) : null}

        {this.props.vendor ? (
          <VendorHeader vendor={this.props.vendor} imageUrl={this.props.vendorImageUrl} />
        ) : null}

        <div className="content-container">
          <div className="content">
            {!this.props.vendor ? (
              this.renderSidebar(isAlcoholComingSoon)
            ) : (
              <div className="applied-filters">
                <div className="count">
                  {this.props.totalProducts} Product{this.props.totalProducts > 1 ? 's' : null}
                </div>
              </div>
            )}
            <Subcategories
              populatedSubcategories={this.props.populatedSubcategories}
              isWebstand={Boolean(this.props.vendor)}
              isAlcoholComingSoon={isAlcoholComingSoon}
            />

            {this.props.category && (
              <BottomNav
                nextSubcategory={this.props.nextSubcategory}
                previousSubcategory={this.props.previousSubcategory}
                categoryId={this.props.category.id}
              />
            )}

            {this.props.totalProducts > 0 && (
              <a className="js-mobile-to-top product-listings-view__back-to-top" href="#">
                Back to Top
              </a>
            )}
          </div>
        </div>
      </MarketLayout>
    );
  }
}

ProductListingsPage.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    shortName: PropTypes.string.isRequired,
  }),
  renderProducts: PropTypes.func.isRequired,
  upcomingPreorderPeriods: PropTypes.array,
  populatedSubcategories: PropTypes.array.isRequired,
  currentFulfillmentDay: PropTypes.string.isRequired,
  categories: PropTypes.array,
  foodhub: PropTypes.object,
  user: PropTypes.object,
  tagName: PropTypes.string,
  tagDescription: PropTypes.string,
  tagSlug: PropTypes.string,
  vendor: PropTypes.shape({
    city: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
  vendorImageUrl: PropTypes.string,
  totalProducts: PropTypes.number,
  nextSubcategory: PropTypes.object,
  previousSubcategory: PropTypes.object,
  showSignInModal: PropTypes.bool,
  onClickPreorderCTA: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    googleAuthenticateClientId: PropTypes.string.isRequired,
  }).isRequired,
  features: PropTypes.array,
  replaceModal: PropTypes.func.isRequired,
};

ProductListingsPage.pageName = 'Category';

function mapDispatchToProps(dispatch) {
  return {
    renderProducts: (nextRenderProductIds) =>
      dispatch(actions.renderProducts(nextRenderProductIds)),

    onClickPreorderCTA: (preorderPeriod) => {
      dispatch(modalActions.openDayChooserModal({closeOnBack: true, preorderPeriod}));
    },

    replaceModal: (modal, props) => dispatch(modalActions.replaceModal({modal, props})),
    showSignUpModalFlow: () => dispatch(signUpModalActions.showSignUpModalFlow()),
  };
}

function mapStateToProps(state) {
  const vendorProducts = uniq(
    state.populatedSubcategories
      .reduce(
        (memo, subcategory) =>
          memo.concat(
            subcategory.sections.reduce(
              (products, section) => products.concat(section.productInfos),
              [],
            ),
          ),
        [],
      )
      .flatMap((subcategory) => [subcategory.id]),
  );

  return {
    categories: state.categories,
    foodhub: state.foodhub,
    user: state.user,
    populatedSubcategories: state.populatedSubcategories,
    category: state.category,
    previousSubcategory: state.previousSubcategory,
    nextSubcategory: state.nextSubcategory,
    upcomingPreorderPeriods: state.upcomingPreorderPeriods,
    currentFulfillmentDay: state.currentFulfillmentDay,
    // following indicate tag or webstand modalities
    tagName: state.tag && state.tag.name,
    tagDescription: state.tag && state.tag.description,
    tagSlug: state.tag && state.tag.slug,
    vendor: state.vendor && {
      city: state.vendor.city,
      name: state.vendor.name,
      description: state.vendor.description,
      slug: state.vendor.slug,
      websiteUrl: state.vendor.websiteUrl,
    },
    vendorImageUrl: state.vendor && state.vendorImageUrl,
    totalProducts: vendorProducts.length,
    settings: state.settings,
    features: state.features,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListingsPage);
