import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
  MarketFlash,
  MarketFlashHeader,
  MarketFlashBody,
  MarketFlashInfo,
} from 'web/components/market_flash';

export default class PreorderAnnouncement extends Component {
  clickCallToAction() {
    if (this.props.shouldRedirect) {
      window.location.href = this.props.preorderPeriod.categoryId;
      return;
    }
    if (this.props.onClickCTA) {
      this.props.onClickCTA(this.props.preorderPeriod);
    }
  }

  render() {
    const {announcement} = this.props.preorderPeriod;

    if (!announcement.title) {
      return null;
    }

    return (
      <div className="preorder-announcement" data-testid="preorder-announcement">
        <MarketFlash>
          <MarketFlashHeader>{announcement.title}</MarketFlashHeader>
          <MarketFlashBody>
            <div dangerouslySetInnerHTML={{__html: announcement.body}} />
            {announcement.info && announcement.info.length > 0 && (
              <MarketFlashInfo info={announcement.info} />
            )}
            <div className="product-announcement-banner__cta">
              <button
                className="button button-is-secondary"
                onClick={this.clickCallToAction.bind(this)}
              >
                {announcement.cta}
              </button>
            </div>
          </MarketFlashBody>
        </MarketFlash>
      </div>
    );
  }
}

PreorderAnnouncement.propTypes = {
  preorderPeriod: PropTypes.shape({
    announcement: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      cta: PropTypes.string,
      info: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  onClickCTA: PropTypes.func,
  shouldRender: PropTypes.bool,
};
