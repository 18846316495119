import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class MarketFlashHeader extends Component {
  render() {
    return (
      <div>
        <h2 className={classNames('market-flash-banner__heading', this.props.className)}>
          {this.props.children}
        </h2>
      </div>
    );
  }
}

MarketFlashHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export class MarketFlashBody extends Component {
  render() {
    return (
      <div className={classNames('market-flash-banner__body', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

MarketFlashBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export class MarketFlash extends Component {
  render() {
    return (
      <div className="market-flash-banner">
        {this.props.onDismiss ? (
          <div className="market-flash-banner__close_button" onClick={this.props.onDismiss}>
            <i className="icon icon-thin-x" />
          </div>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

MarketFlash.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onDismiss: PropTypes.func,
};

export class MarketFlashInfo extends Component {
  render() {
    const steps = this.props.info.map((step, i) => {
      return <li key={i}>{step}</li>;
    });
    return (
      <div className="market-flash-banner__info">
        <ul>{steps}</ul>
      </div>
    );
  }
}

MarketFlashInfo.propTypes = {
  info: PropTypes.arrayOf(PropTypes.string),
};
